<template lang="pug">
.tariff-page
  invoice-page
    .block
      go-back.go-back(:is-go-back="false" :to="Tabs.Settings.Main") Вернуться к настройкам
      .page-title__wrapper
        h1.main-page-title.settings Управление тарифом
      settings-panel
        template(#header) Тариф
        my-tariff
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import MyTariff from "@/components/pages/settings/tariffPage/MyTariff.vue";
import InvoicePage from "~/views/settings/tariff/InvoicePage.vue";
import GoBack from "~/components/other/GoBack.vue";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "TariffPage",
  components: {
    GoBack,
    InvoicePage,
    SettingsPanel,
    MyTariff,
  },
  setup() {
    return {
      Tabs,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/pages/main";

.tariff-page {
  @include page-view;
}

.go-back {
  margin-top: 24px;
  width: fit-content;
}
</style>
